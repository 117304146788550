import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import API from '../api/api';
import { Text, Title, Button, Box } from '../components/Core';
import Section from '../components/Section';
import { Container, Col, Row } from 'react-bootstrap';
import { getProperty } from '../utils/helperFn';
import { navigate } from 'gatsby';
import GlobalContext from '../context/GlobalContext';
import FullScreenLoader from '../components/FullScreenLoader';
import { AgreementSwitch } from '../components/Core/Switch';
import Loader from '../assets/image/gif/loader.gif';
import TimeSlotPicker from '../sections/CheckoutSteps/StepThree/TimeSlotPicker';
import { Form, Formik } from 'formik';
import { isMobile } from 'react-device-detect';
import BigRoundButton from '../components/BigRoundButton';
import { Modal } from "react-bootstrap";
import { TinySwitch } from '../components/Core/Switch';

const SchedulePage = (props) => {

  const gContext = useContext(GlobalContext);
  const [calendarLoading, setCalendarLoading] = useState(false);
  const [addressId, setAddressId] = useState(null);
  const [woIdType, setWoIdType] = useState('');
  const registrationData = gContext.goGetRegistrationData();
  const [dateFilter, setDateFilter] = useState(undefined);
  const [firstDate, setFirstDate] = useState(null);
  const [initialDate, setInitialDate] = useState('');
  const [initialTime, setInitialTime] = useState('');
  const [showNoSlotsModal, setShowNoSlotsModal] = useState(false);
  const [calendarData, setCalendarData] = useState(null);
  const [earlierAvailability, setEarlierAvailability] = useState(false);
  const [loaderTitle, setLoaderTitle] = useState('');

  const customDate = registrationData?.Date || gContext?.goGetRegistrationData()?.Date || undefined;
  const customTime = registrationData?.Time || gContext?.goGetRegistrationData()?.Time || undefined;

  let woId = '';

  useEffect(() => {
    /* We redirect user to home page if no workOrderId provided in URL */
    if (!new URLSearchParams(props.location.search).get('woId')) {
      navigate('/');
    } else {
      setLoaderTitle('Loading your order details...');
      gContext.showFullScreenLoader();
      woId = new URLSearchParams(props.location.search).get('woId');
      getWorkOrder(woId);
    }
  }, []);

  const onNoSlotsModalHide = () => {
    setShowNoSlotsModal(false)
  }

  useEffect(() => {
    const fetchDataForDateRange = async (startDate) => {
      if (gContext.goGetRegistrationData() && gContext.goGetRegistrationData().dateFilter) {
        setDateFilter(gContext.goGetRegistrationData().dateFilter);
      } else {
        setDateFilter('EARLIEST');
      }
  
      if (typeof window !== 'undefined') {
        window.scrollTo(0, 0);
      }
      try {
        const endDate = formatDate(addDaysToDate(startDate, 30));
        const response = await API.get(
          `/FieldServiceModule/v1.0/ServiceAppointment/calendar?start=${startDate}&end=${endDate}&type=${woIdType}&addressId=${addressId}`
        );

        return response.data.data;
      } catch (error) {
        throw error;
      }
    };

    const currentDate = moment(new Date()).format('YYYY-MM-DD');
    const nextDate = moment(currentDate).add(30, 'days').format('YYYY-MM-DD');

    gContext.setQuoteLoading(true);
    setLoaderTitle('Loading available install slots, please wait...');
    gContext.showFullScreenLoader();

    if (addressId && woIdType) {
      Promise.all([fetchDataForDateRange(currentDate), fetchDataForDateRange(nextDate)])
      .then(([currentData, nextData]) => {
        const mergedCalendarData = {};

        [currentData, nextData].forEach((data) => {
          data.forEach((record) => {
            const date = record.Date;
            if (!mergedCalendarData[date]) {
              mergedCalendarData[date] = {
                AM: record.AM,
                PM: record.PM,
                AMConfigId: record.AM ? record.AMConfig.id : undefined,
                PMConfigId: record.PM ? record.PMConfig.id : undefined,
                AmOptimizer: record.AM ? record.AMConfig.optimizer : undefined,
                PmOptimizer: record.PM ? record.PMConfig.optimizer : undefined,
              };
            }
          });
        });

        setCalendarData(mergedCalendarData);

        let firstDate;
        Object.keys(mergedCalendarData).some((date) => {
          const record = mergedCalendarData[date];
          if (record.AM || record.PM) {
            firstDate = { ...record, Date: date };
            return true;
          }
          return false;
        });

        if (firstDate) {
          const firstTime = firstDate.AM ? 'AM' : 'PM';
          setFirstDate(firstDate);
          setInitialDate(moment(new Date(firstDate.Date)).format('DD MMM YYYY'));
          setInitialTime(firstTime);

          gContext.goSetRegistrationData({
            ...gContext.goGetRegistrationData(),
            calendarData: mergedCalendarData,
            dateFilter: dateFilter,
            Date: firstDate.Date,
            Time: firstTime,
            firstDate: firstDate,
          });

        } else {
          setShowNoSlotsModal(true);
        }
        
        gContext.setQuoteLoading(false);
        gContext.hideFullScreenLoader();
        setLoaderTitle('');
      })
      .catch((error) => {
        setShowNoSlotsModal(true);
        gContext.hideFullScreenLoader();
        setLoaderTitle('');
        gContext.setQuoteLoading(false);
      });
    }
  }, [addressId, woIdType]);

  const getWorkOrder = async (woId) => {
    if (woId) {
      await API.get(`/FieldServiceModule/v1.0/db/WorkOrder/${woId}?&withLinks=true`)
        .then((resp) => {
          const address = resp.data.data.links.find(
            (associatedRecord) => associatedRecord.entity === 'CrmModule:Address',
          );
          gContext.hideFullScreenLoader();
          setLoaderTitle('');
          if (address) {
            let addressID = address.id;
            setAddressId(address.id);
            setWoIdType(resp.data.data.type);
          } else {
            navigate('/');
          }
        })
        .catch((error) => {
          gContext.hideFullScreenLoader();
          setLoaderTitle('');
          gContext.setAPIErrorMsg({
            title: 'Error',
            message: error.response ? error.response?.data.message : error
          });
        });
    }
  };

  const formatDate = (date) => {
    return moment(date).format('YYYY-MM-DD');
  };

  const clearCustomDate = (dateFilter) => {
    gContext.goSetRegistrationData({
      ...gContext.goGetRegistrationData(),
      Date: undefined,
      Time: undefined,
      dateFilter,
    });
  };

  const addDaysToDate = (startDate, days) => {
    if (typeof startDate === 'string') {
      startDate = moment(startDate).toDate();
    }
    return startDate.setDate(startDate.getDate() + days);
  };

  const isCustomDateSelected = () => {
    if (registrationData?.Date && registrationData?.Time && dateFilter === 'CUSTOM') {
      return true;
    } else {
      return false;
    }
  };

  const LegalSwitchCard = ({ color = 'primary', text, children, ...rest }) => (
    <Box
      bg="light"
      border="1px solid"
      borderColor="border"
      borderRadius={10}
      className={`d-flex`}
      {...rest}
      css={`
        min-width: 100%;
        width: 100%;
        .legal-text {
          font-size: 1em;
          line-height: 1.5em;
        }
      `}
    >
      <Text className="legal-text pt-3 pl-0 pr-0 pl-lg-5 pr-0 pr-md-5">{text}</Text>
      {children}
    </Box>
  );

  const submitAppointment = (values) => {
    const woId = new URLSearchParams(props.location.search).get('woId');

    if (woId) {
      setLoaderTitle('Submitting your appointment...');
      gContext.showFullScreenLoader();
      
      const postData = {
        EarlierAvailability: earlierAvailability,
        Date: registrationData?.Date,
        TimeBlock: registrationData?.Time,
        scheduleId: registrationData?.calendarData[registrationData?.Date][registrationData?.Time + 'ConfigId'],
        properties: {
          RequestedBy: 'CUSTOMER',
          RescheduleReason: 'INSTALL_REMINDER_RESCHEDULED',
          AppointmentDate: registrationData?.Date,
          Description:
            'Customer has requested to reschedule from their install reminder notification',
        },
      }

      try {
        API.post(`FieldServiceModule/v1.0/ServiceAppointment/WorkOrder/${woId}/reserve`, postData)
          .then((resp) => {
            gContext.hideFullScreenLoader();
            setLoaderTitle('');
            navigate(`/appointment-success?woId=${woId}/`);
          })
          .catch((error) => {
            gContext.hideFullScreenLoader();
            setLoaderTitle('');
            gContext.setAPIErrorMsg({
              title: 'Error',
              message: error.response ? error.response?.data.message : error
            });
          });
      } catch (e) {
        gContext.hideFullScreenLoader();
        setLoaderTitle('');
        console.log(e);
      }
    } else {
      gContext.hideFullScreenLoader();
      setLoaderTitle('');
      gContext.setAPIErrorMsg({
        title: 'Error',
        message: 'There was an error!',
      });
    }
  };

  return (
    <>
      <FullScreenLoader title={loaderTitle} />
      <Row className="justify-content-center">
        <Col sm={12} md={6} className="mb-3 mt-5 pt-5">
          <div className="checkoutContractBox">
            <span
              style={{ fontWeight: 800, fontSize: '1.3em' }}
              className="d-block text-center mt-2"
            >
              Schedule your installation and get connected to our ultra fast fibre network!
            </span>
            <Row className={`p-4 ${gContext.quoteLoading || gContext.totalLoading ? ' no-slots-available' : ''}`}>
              {/* Go Live - First available data */}
              <Col xs={12} sm={6} className="p-2">
                <div
                  className={`optionButton ${dateFilter === 'EARLIEST' ? 'selected' : ''}`}
                  onClick={() => {
                    setDateFilter('EARLIEST');
                    gContext.goSetRegistrationData({
                      ...gContext.goGetRegistrationData(),
                      Date: firstDate.Date,
                      Time: initialTime,
                      dateFilter: 'EARLIEST',
                    });
                  }}
                >
                  <span style={{ fontWeight: 400 }}>Go Live</span>
                  <br />
                  <span className="mt-2" style={{ fontWeight: 800, fontSize: '1.2em' }}>
                    {initialDate} - {initialTime}
                  </span>
                </div>
              </Col>

              {/* Go Live - Specific date */}
              <Col xs={12} sm={6} className="p-2">
                <div
                  className={`optionButton ${dateFilter === 'CUSTOM' || isCustomDateSelected() ? 'selected' : ''
                    }`}
                  onClick={() => {
                    clearCustomDate('CUSTOM')
                    setDateFilter('CUSTOM');
                  }}
                >
                  <Row className="justify-content-between">
                    <Col xs={12}>
                      <span style={{ fontWeight: 400 }}>Go Live</span>
                      <br />
                      <span
                        style={{ fontWeight: 800, fontSize: isMobile ? '10px' : '1.2em' }}
                        className="mt-2"
                      >
                        {gContext.goGetRegistrationData()?.Date &&
                          gContext.goGetRegistrationData()?.Time &&
                          isCustomDateSelected()
                          ? `${moment(gContext.goGetRegistrationData()?.Date, 'YYYY-MM-DD').format(
                            'DD MMM YYYY',
                          )} - ${gContext.goGetRegistrationData()?.Time}`
                          : 'Later'}
                      </span>
                      <br style={{ minHeight: 20 }} />
                    </Col>
                  </Row>
                </div>
              </Col>

              {
                dateFilter === 'EARLIEST' &&
                <div>
                  <Col xs={12} className={`p-2 mt-4`}>
                    <Row>
                      <Col xs={12}>
                        <span style={{ fontWeight: 800, fontSize: '1.3em' }} className="mt-2">
                          Yay, we can get you as early as {initialDate} - {initialTime}
                        </span>
                      </Col>
                      <Col xs={12} className="mt-3">
                        <span style={{ fontWeight: 400 }}>
                          This is the fastest we can connect you full fibre. But the good news is,
                          your property is all ready for You!
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </div>
              }

              {dateFilter === 'CUSTOM' && !isCustomDateSelected() && (
                <TimeSlotPicker calendarData={calendarData} firstDate={firstDate?.Date} />
              )}

              {/* Custom Date Explanation */}
              {isCustomDateSelected() && (
                <Col xs={12} className={`p-2 mt-4`}>
                  <Row>
                    <Col xs={12}>
                      <span style={{ fontWeight: 800, fontSize: '1.3em' }} className="mt-2">
                        Alright, we can install your service on{' '}
                        {moment(customDate, 'YYYY-MM-DD').format('DD MMM YYYY')} - {customTime}
                      </span>
                    </Col>
                    <Col xs={12} className="mt-3">
                      <div style={{ fontWeight: 400 }} className="mt-3">
                        <span>On the day of your install, our engineers will call you when they are on their way.
                          Once they arrive, they will talk you through the installation and make sure we install in the optimal place for You.</span>
                      </div>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </div>
        </Col>
        <Col sm={12} lg={12} className="mt-3 pl-0 text-center">
            <TinySwitch
              setValue={(e) => { setEarlierAvailability(e) } }
              value={earlierAvailability}
            />
            <span style={{
              position: 'relative',
              bottom: '5px'
            }}>Please contact me if an earlier appointment is available.</span>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col sm={12} md={4} className="text-center mb-4 mt-4">
          <BigRoundButton
            title="Submit"
            onClick={() => submitAppointment()}
            disabled={(!registrationData?.Date || !registrationData?.Time) && !gContext.goGetRegistrationData()?.noSlotsAvailable || gContext.quoteLoading || gContext.totalLoading}
          />
        </Col>
        {/* Go Back */}
        <Col sm={12} className="text-center mt-5">
          <p
            style={{ cursor: 'pointer' }}
            onClick={() =>
              gContext.goSetRegistrationData({
                ...gContext.goGetRegistrationData(),
                checkoutStep: 1,
              })
            }
          >
            ← Go Back
          </p>
        </Col>
      </Row>
      <Modal
        show={showNoSlotsModal}
        onHide={() => onNoSlotsModalHide()}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ borderRadius: '10px' }}
      >
        <Modal.Body className="p-4">
          <h4 className="text-center">No available slots</h4>
          <p className="text-center">There are no availabile installation slots at the moment.</p>
          <div className="desktop-center">
            <Button
              className="medium-button blue-bg mb-3 mr-3"
              onClick={() => {
                gContext.goResetRegistrationData()
                navigate('/');
              }}
            >
              Go back to the homepage
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default SchedulePage;
